<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilterUsedCoupon"
    width="80vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <div class="mt-3">
        <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            placeholder="Search Customer Name"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="mt-3">
        <label class="label-title">Redemption Date</label>
        <div class="input-container">
          <datetime
            type="date"
            :input-style="styleDatetime"
            v-model="filter.redeem_date"
            placeholder="Please Select Date"
            format="dd/MM/yyyy"
            value-zone="Asia/Bangkok"
            ref="redeemDateStart"
          >
          </datetime>
          <div
            :class="'icon-primary text-right'"
            @click="$refs.redeemDateStart.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    clearFilter() {
      this.filter.search = null;
      this.filter.redeem_date = null;
      this.filter.used_date = null;
      this.allSelected = false;
      this.selected = [];
      this.filter.status = null;
    },
    submitAction() {
      this.filter.page = 1;
      if (!this.filter.redeem_date) {
        this.filter.redeem_date = null;
      }
      this.$emit("searchFilter", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.label-title {
  font-weight: bold;
}
</style>
